import "./bkobtn.css";
import { useState } from "react";
import defaultLogo from "../../Assets/defaultImg.svg";

const BookingOfferButton = ({ content, type }) => {
    const [showImage, setShowImage] = useState(false);

    const handleButtonClick = () => {
        setShowImage(true);
    };

    // console.log(type);

    return (
        <div 
            className="bko-container"
            style={{ paddingTop: showImage ? '25vw' : '5vw' }}
        >
            <button
                onClick={handleButtonClick}
                style={{ display: showImage ? 'none' : 'block' }}
                className="button"
            >
                <h3>Show</h3>
            </button>
            
            <img
                src={defaultLogo}
                alt="Moving Image"
                className={`image ${showImage ? 'show' : ''}`}
            />

            {type === "image" && 
            <img
                src={content}
                alt="Static Image"
                className={`static-image ${showImage ? 'show' : ''}`}
            />
            }

            {type === "video" && 
            <video className={`static-video ${showImage ? 'show' : ''}`} controls >
                <source src={content} type="video/mp4"  />
            </video>
            }

            {type === "text" && (
            <textarea
            disabled
            value={content}
            className={`static-text ${showImage ? 'show' : ''}`}
            />
            )}

        </div>
    );
};

export default BookingOfferButton;
