import "./commercialPage.css";
import SearchBar from "../../Components/SearchBar/SearchBar"
import DispCard from "../../Components/Display/DispCard"
import Advertisements from "../../Components/Advertisements/Advertisements";


const CommercialPage = () => {

    let ad1 = "";
    let ad2 = "";

    const ads = [
        ad1,
        ad2,
    ].filter(plan => plan); 

    return(
        <>
        <div className="commercial">

            <div className="searchBarDiv">
                <SearchBar pageType="commercial"/>
            </div>

            <div className="titleDiv">
                <h1>Commercial Properties</h1>
            </div>

            <div className="ads">
                <Advertisements pageType="commercial"/>
            </div>

            <div className="outputDiv">
                <DispCard pageType="commercial"/>
            </div>

            <div className="extraSpace"></div>
            
        </div>
        <div className="extraSpace"></div>
        <div className="extraDiv"></div>
        </>
    );
}

export default CommercialPage;