import "./ads.css";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useEffect, useState, useMemo } from "react";
import axios from "axios";

const Advertisements = ({ ads, pageType }) => {
  const [data, setData] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://property-stock-backend.vercel.app/super-admin/advertisement');
        setData(response.data.response.advertisements);
        // console.log(response.data.response.advertisements);
      } catch (error) {
        console.error('Error fetching Ads:', error);
      }
    };

    fetchData();
  }, []);

  const filteredImages = useMemo(() => {
    let image1, image2;

    switch (pageType) {
      case 'residential':
        image1 = data['residential-1'];
        image2 = data['residential-2'];
        break;
      case 'commercial':
        image1 = data['commercial-1'];
        image2 = data['commercial-2'];
        break;
      case 'plotland':
        image1 = data['plot-land-1'];
        break;
      case 'financial':
        image1 = data['financial-1'];
        break;
      case 'logistics':
        image1 = data['logistics-1'];
        image2 = data['logistics-2'];
        break;
      case 'ourservices':
        image1 = data['our-services-1'];
        image2 = data['our-services-2'];
        break;
      default:
        break;
    }

    return [image1, image2].filter((image) => image);
  }, [pageType, data]);

  useEffect(() => {
    setImages(filteredImages);
  }, [filteredImages]);

  // Debugging
  // console.log('Images:', images);

  return (
    <div className="adContainer">
     {images.length > 0 && (
  <Slide
    transitionDuration={400}
    duration={3000}
    infinite={true}
    pauseOnHover={true}
  >
    {images.map((each, index) => (
      <div key={index} className="each-slide">
        <img src={each} alt={`Slide ${index + 1}`} />
      </div>
    ))}
  </Slide>
)}
    </div>
  );
};

export default Advertisements;
