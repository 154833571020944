import "./npFilter.css";
import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {options} from "../../Assets/options";
import { FilterDataContext } from '../../Context/FilterContext';
import {useNavigate} from "react-router-dom"


const NPFilter = () => {

    const { updateFilteredData } = useContext(FilterDataContext);
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState('residential');
    const [propertyType, setPropertyType] = useState("");
    const [bhk, setBhk] = useState("");
    const [location, setLocation] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [minPriceTag, setMinPriceTag] = useState("");
    const [maxPriceTag, setMaxPriceTag] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [minArea, setMinArea] = useState("");
    const [maxArea, setMaxArea] = useState("");
    const [data, setData] = useState([]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://property-stock-backend.vercel.app/project/approved');
                const data = response.data.response;
                setData(data);
                // console.log(data);
            } catch (error) {
                console.error('Error fetching Data', error);
            }
        };
        fetchData();
    }, [])

    const getKeyFromValue = (value) => {
        // Merge residential and commercial types into a single object to search through both
        const combinedTypes = { ...options.residentialType, ...options.commercialType, ...options.bhk };
    
        // Find the key based on the display value
        for (const [key, displayValue] of Object.entries(combinedTypes)) {
          if (displayValue === value) {
            return key;
          }
        }
        // Return null or handle if not found
        return null;
    };



    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handlePropertyTypeChange = (event) => {
        setPropertyType(event.target.value);
    
    };
    
    const handleBhkChange = (event) => {
        setBhk(event.target.value);
    };

    const handleMinPriceChange = (event) => {
        setMinPrice(event.target.value);
    }

    const handleMaxPriceChange = (event) => {
        setMaxPrice(event.target.value);
    }
    
    const handleMinPriceTagChange = (event) => {
        setMinPriceTag(event.target.value);
    }
    const handleMaxPriceTagChange = (event) => {
        setMaxPriceTag(event.target.value);
    }

    const handleMinAreaChange = (event) => {
        setMinArea(event.target.value);
    }

    const handleMaxAreaChange = (event) => {
        setMaxArea(event.target.value);
    }

    const handleToggle = () => {
        setIsSelected((prevState) => !prevState);
    };

    


    let propertyTypes = [];
    let bhks = [];
    let priceTags = ["K", "L", "Cr"]

    if(selectedCategory === "residential"){
        propertyTypes = ["Flat", "Apartment", "Rowhouse", "Villa", "Bunglow", "Duplex", "Penthouse"];
        bhks = ["1 RK Studio Apartment", "1 BHK", "2 BHK", "2.5 BHK", "3 BHK", "3.5 BHK", "4 BHK", "5+ BHK"];
    }
    if(selectedCategory === "commercial"){
        propertyTypes = ["Shop", "Office", "Office Space", "Business Park", "Data Centre", "Shopping Mall", "Shopping Complex", "Industrial Space", "Warehouse / Godown"];
    }


    function adjustPrice(price,tag) {
        if (tag === "K") {
            price *= 1000;
        }
        if (tag === "L") {
            price *= 100000;
        }
        if (tag === "Cr") {
            price *= 10000000;
        }
        return price;
    }
    




    let trimLocation = location.trim();
    let filter1 = data.filter(item => {
        return item.projectAddress.toLowerCase().includes(trimLocation.toLowerCase());
    });
    let filter2 = filter1.filter((item) => {
        return item.projectCategory.toLowerCase().includes(selectedCategory.toLowerCase());
    });
    let filter3 = filter2.filter((item) => {
        return propertyType ? item.type === getKeyFromValue(propertyType) : filter2;
    });
    let filter4 = filter3.filter((item) => {
        return bhk? item.bhk.toLowerCase().includes(getKeyFromValue(bhk).toLowerCase()) : filter3 ;
    });
    let filter5 = filter4.filter((item) => {
        const startingPrice = item.startingPrice;

        let newMinPrice = adjustPrice(minPrice,minPriceTag);
        let newMaxPrice = adjustPrice(maxPrice,maxPriceTag);
      
        if (minPrice.length > 0 && maxPrice.length > 0) {
          return startingPrice >= newMinPrice && startingPrice <= newMaxPrice;
        }
        else if (minPrice.length > 0 && maxPrice.length <= 0) {
          return startingPrice >= newMinPrice;
        }
        else if (maxPrice.length > 0 && minPrice.length <= 0) {
          return startingPrice <= newMaxPrice;
        } 
        else {
          return true;
        }
    });

    

    let filter6 = selectedCategory === 'commercial'
    ? filter5.filter((item) => {
        const startingSize = parseFloat(item.startingSize);
        const min = parseFloat(minArea) || -Infinity;
        const max = parseFloat(maxArea) || Infinity;

        // Ensure startingSize is valid and within the specified range
        return !isNaN(startingSize) && startingSize >= min && startingSize <= max;
    })
    : filter5;


    function isEmpty(value) {
        if (value == null) return true;
        if (typeof value === 'object' && Object.keys(value).length === 0) return true;
        if (Array.isArray(value) && value.length === 0) return true;
        if (typeof value === 'string' && value.trim().length === 0) return true;
        return false;
      }



    let filter7 = filter6.filter((item) => {
        // Check if isSelected is true
        if (isSelected) {
        //   return item.bookingOffer; // Return item with bookingOffer if isSelected is true
        return !isEmpty(item.bookingOffer)
        }
        return true; // Keep the data as it is if isSelected is false
    });

      
      




    const handleSubmit = () => {
        // console.log(filter7);
        // updateFilteredData(filter7);
        updateFilteredData(filter7.sort((a, b) => Number(b.rating) - Number(a.rating)));
        navigate('/newprojects/results');

    }







    return(
        <>
            <div className="npFilter-container">

                <h1>New Projects Search</h1>

                <div className="npLocationInput">
                    <input 
                        type="text"
                        placeholder="Enter Location / City"
                        value={location}
                        onChange={handleLocationChange}
                    ></input>
                </div>


                <div className="npCategory">
                    <h3>Category :</h3>

                    <label className="radio-label">
                    <input
                    type="radio"
                    value="residential"
                    checked={selectedCategory === 'residential'}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === 'residential' ? 'checked' : ''}`}>
                    Residential
                    </div>
                    </label>

                    <label className="radio-label">
                    <input
                    type="radio"
                    value="commercial"
                    checked={selectedCategory === 'commercial'}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === 'commercial' ? 'checked' : ''}`}>
                    Commercial
                    </div>
                    </label>

                </div>

                <div className="npResidential">
                    <div>
                        <h3>Residential Type :</h3>

                        <select id="propertyType" value={propertyType} onChange={handlePropertyTypeChange} >
                            <option value="">Select Property Type</option>
                            {propertyTypes.map((type, index) => (
                                <option key={index} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        {selectedCategory === "residential" && (
                            <>
                                <h3>BHK :</h3>
                                <select id="bhk" value={bhk} onChange={handleBhkChange}>
                                    <option value="">Select BHK</option>
                                    {bhks.map((bhk, index) => (
                                        <option key={index} value={bhk}>
                                        {bhk}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>


                </div>

                <div className="npPrice">
                    <div className="minP">

                        <h3>Minimum Price :</h3>


                        <div>
                            <input
                            type="number"
                            placeholder="Enter Minimum Price"
                            value={minPrice}
                            onChange={handleMinPriceChange}
                            />

                            <select id="priceTag" value={minPriceTag} onChange={handleMinPriceTagChange}>
                                <option value="">K, L ,Cr</option>
                                {priceTags.map((priceTag, index) => (
                                    <option key={index} value={priceTag}>
                                        {priceTag}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className="minP">
                        <h3>Maximum Price :</h3>

                        <div>
                            <input
                            type="number"
                            placeholder="Enter Maximum Price"
                            value={maxPrice}
                            onChange={handleMaxPriceChange}
                            />

                            <select id="priceTag" value={maxPriceTag} onChange={handleMaxPriceTagChange}>
                                    <option value="">K, L ,Cr</option>
                                    {priceTags.map((priceTag, index) => (
                                            <option key={index} value={priceTag}>
                                                {priceTag}
                                            </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>

                {selectedCategory === "commercial" && (
                <div className="npArea">

                    <div className="minArea">
                        <h3>Sq.Ft. Minimum Area :</h3>
                        <input
                        type="number"
                        placeholder="Enter Minimum Area"
                        value={minArea}
                        onChange={handleMinAreaChange}
                        />
                    </div>

                    <div className="minArea">
                        <h3>Sq.Ft. Maximum Area :</h3>
                        <input
                        type="number"
                        placeholder="Enter Maximum Area"
                        value={maxArea}
                        onChange={handleMaxAreaChange}
                        />
                    </div>

                </div>
                )}

                <div className="npBOBtn">
                    <button
                        className={isSelected ? 'toggle-button selected' : 'toggle-button'}
                        onClick={handleToggle}
                        >
                        Booking Offers
                    </button>
                </div>

                <div className="npBtn">
                    <button onClick={handleSubmit}>Search</button>
                </div>

                <div className="extraDiv"></div>

            </div>
        </>
    );
}

export default NPFilter;