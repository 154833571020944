import "./residentialPage.css";
import {useState, useEffect, useContext} from "react";
import SearchBar from "../../Components/SearchBar/SearchBar"
import ResidentialCard from "../../Components/Cards/ResidentialCard";
import { FilterDataContext } from '../../Context/FilterContext';
import Advertisements from "../../Components/Advertisements/Advertisements";

const ResidentialResults = () => {

    const { filteredData } = useContext(FilterDataContext);
    const [text, setText] = useState('Loading...');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setText('No Properties Available');
        }, 10000); // Timeout after 5 seconds
    
        return () => clearTimeout(timeoutId);
      }, []);


    let ad1 = "";
    let ad2 = "";

    const ads = [
        ad1,
        ad2,
    ].filter(plan => plan); 





    return(
        <>
        <div className="residential">

            <div className="searchBarDiv">
                <SearchBar pageType="residential"/>
            </div>

            <div className="titleDiv">
                <h1>Residential Properties</h1>
            </div>

            <div className="ads">
                <Advertisements ads={ads} pageType="residential"/>
            </div>

            <div className="outputDiv">
                <div className="dispCards">
                {filteredData.length !== 0 ? (
                    filteredData.map((e) => {
                    return (
                        <div key={e._id}>
                            <ResidentialCard {...e} />
                        </div>
                    );
                    })
                ) : (
                    <div>{text}</div>
                )}
                    
                </div>
            </div>

            <div className="extraSpace"></div>
            <div className="extraSpace"></div>
            <div className="extraDiv"></div>
            
        </div>
        </>
    );
}

export default ResidentialResults;