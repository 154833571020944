import "./homepage.css";
import HomeMenu from "../../Components/HomeMenu/HomeMenu";
import {useState, useEffect} from "react";
import NewMenu from "../../Components/NewMenu/NewMenu";
import AddBtnIcon from "../../Assets/addBtn.svg";
import axios from "axios";

const HomePage = () => {

    const [isNewMenuVisible, setIsNewMenuVisible] = useState(false);

    const [showAddBtn, setShowAddBtn] = useState(false);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://property-stock-backend.vercel.app/super-admin/advertisement');
          setShowAddBtn(response.data.response.addProject);
        //   console.log(response.data.response.addProject);
        } catch (error) {
          console.error('Error fetching Ads:', error);
        }
      };
  
      fetchData();
    }, []);

    const handleFlBtnClick = () => {
        setIsNewMenuVisible(!isNewMenuVisible);
    };

    return(
        <>   
        <div className="homepage">
            <div className="homePageMenu">
                <HomeMenu/>  
            </div>
            <div className="homePageText">
                <h1>PropertiesStock</h1>
                <h2>- YOUR TRUSTED SOURCING FACILITY PORTAL</h2>
                <h3>At PropertiesStock, We Believe in Empowering Your Real Estate Journey. As the ultimate Sourcing Facility portal, we offer a comprehensive suite of services designed to streamline your experience and maximize your outcomes.  PropertiesStock is Providing only Informative Facility, Whether you're a seasoned investor or a first-time homebuyer, count on PropertiesStock to provide the resources, support, and expertise according our needs</h3>
            </div>

            {showAddBtn && (
            <button className="flAddBtn" onClick={handleFlBtnClick}>
                <img src={AddBtnIcon} alt="Add Properties Logo" />Add Properties <br/> <span className="flBtnRed">FREE</span>
            </button>
            )}

            {/* {isNewMenuVisible && <NewMenu />} */}
            {showAddBtn && isNewMenuVisible && <NewMenu />}



        </div>
        </>
    );
}
export default HomePage;