import "./privacy.css";


const LegalTerms = () => {
    return(
        <div className="privacyContainer">
            <div className="legalTop">
                <h1>Disclaimer</h1>
                <h3>
                This site is for information purpose only & Should not a developer website it's a Informative Proparty & real estate Portal only
                </h3>
                <br/>
                <h3>
                1. The purpose of this website (Portal) is to provide information about real estate project & properties in various regions. By accessing this website/Portal, you acknowledge that the information, including brochure, image, Floor plan, booking offers, phone or mobile or WhatsApp number & Marketing materials, is intended for information purpose only. You should not rely on this information to the company's projects. Nothing on this website/Portal should be considered  as Advertising, Marketing, Broking, Selling, an offer for sale, or an invitation to purchase a unit in any project by the company. The company is not responsible for any consequences resulting from action taken by viewers based on the material or information provided on this website/Portal.
                </h3>
                <br/>
                <h3>
                2. Please be aware that company has not verified the information or compliance of the project or properties.  Additionally, the company has not checked the RERA ( Real Estate Regulation Act 2016) registration status of the real estate project listed here. The company does not make any representations regarding the compliance of these projects. It is important for you to ascertain the RERA registration status of the listed real estate project before checking any property purchases.
                </h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Legal Terms & Conditions-</h2>
                <h3>This website/Portal is for guidance only. It does not constitute part of an offer or contract. Design without prior notice. Computer-generated images are the artists impression and indicate the  structures. Using this website/Portal means users have agreed to abide by the following disclaimer  terms & conditions.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Intellectual Data & Properties-</h2>
                <h3>All Content, including images, logos, & materials on this lading page, belong to the project developer or it's licensor. Intellectual property's Laws are strictly for the protection of these materials. Users are strictly forbidden from copying, modifying, distributing, or using any Content without prior written consent.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Markating relevant Data Or Real Estate Details-</h2>
                <h3>The Information on this landing page, including Details about properties, Pricing & availability, may be updated at any time. The company Website/Portal disclaims responsibility for any errors or omissions in the provided Information.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>User Duties & Commitments-</h2>
                <h3>Users Communicate with the authorise person & uses the services or the landing page when submitting inquires or requests. Users must also refrain from illegal or harmful activities,  including hacking, spamming or distributing malicious software or same Information or Data on relevant website or portal company Website or portal not responsible.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Privacy & Data Storage-</h2>
                <h3>The Company may gather, store, & utilize your personal Information as mentioned in it's Privacy policy. We might employ third-party services or tracking technologies, including IP addresses, browser types, operating systems, cookies, or analysis tools, to collect data about your interactions with this landing page & address any  issues in case of complaints, & Communicated with mails or services Mobile number.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Liability or Any damage-</h2>
                <h3>The any Liability or Damages Company denies responsibility for any damages, losses or injuries resulting from the use of the landing page or reliance on the information provided.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Links To External Website or Similar Data or Similar Other Website or portal-</h2>
                <h3>This landing page may include links to third-party Website or resources we do not endorse these third-party sites, nor we are responsible for their Content, privacy, or availability. Similar data on other Website or portal company Website are not responsible.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Terms & Conditions Modifications-</h2>
                <h3>
                    1. The Company reserve the right to revise or amend these Terms and conditions at any time without prior notification, we recommend regularly reviewing these Terms to stay updated on any Modifications.
                    <br/><br/>
                    2. Legally Terms & Conditions not fixed which is changed respect to situations.
                </h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Logo or Trade Mark-</h2>
                <h3>TM all right is Reserve</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Company Profile-</h2>
                <h3>The company is individual proprietorship which is using a third-party Website Server.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Other Services-</h2>
                <h3> Logistics, Financial & investment services or repairing services Lading page Informative purpose.</h3>
            </div>
            
            
            
            <div className="privacyStandardMiddle"/>
        </div>
    );
}

export default LegalTerms;