import "./footer.css";
import {Link} from "react-router-dom";

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-left">
                <h3 className="text">A Division of R.L Industries and Groups</h3>
                <h3 className="number">+91 81081 08689</h3>
            </div>
            <div className="footer-right">
                <Link to="/privacy_policy">Privacy Policy</Link>
                <Link to="/legal_terms_and_condition">Legal Terms and Condition</Link>
            </div>
        </div>
    );
}
export default Footer;