import "./landplotPage.css";
import {useState, useEffect, useContext} from "react";
import SearchBar from "../../Components/SearchBar/SearchBar"
import { FilterDataContext } from '../../Context/FilterContext';
import PlotLandCard from "../../Components/Cards/PlotLandCard";
import Advertisements from "../../Components/Advertisements/Advertisements";

const LandPlotResults = () => {

    const { filteredData } = useContext(FilterDataContext);
    const [text, setText] = useState('Loading...');
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setText('No Properties Available');
        }, 10000); // Timeout after 5 seconds
    
        return () => clearTimeout(timeoutId);
      }, []);

      let pageType;

    let ad1 = "";


    const ads = [
        ad1,

    ].filter(plan => plan); 



    return(
        <>
        <div className="landplot">

            <div className="searchBarDiv">
                <SearchBar pageType="landplot"/>
            </div>

            <div className="titleDiv">
                <h1>Plots / Lands</h1>
            </div>

            <div className="ads">
                <Advertisements ads={ads} pageType="plotland"/>
            </div>

            <div className="outputDiv">
                <div className="dispCards">
                {filteredData.length !== 0 ? (
                    filteredData.map((e) => {
                    return (
                        <div key={e._id}>
                            <PlotLandCard {...e} pageType={pageType} />
                        </div>
                    );
                    })
                ) : (
                    <div>{text}</div>
                )}
                    
                </div>
            </div>

            <div className="extraSpace"></div>
            
        </div>
        <div className="extraSpace"></div>
        <div className="extraDiv"></div>
        </>
    );
}

export default LandPlotResults;