import "./filter.css";
import {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom"
import Slider from "react-slider";
import axios from 'axios';
import { FilterDataContext } from '../../Context/FilterContext';

const PLFilter = () => {

    const { updateFilteredData } = useContext(FilterDataContext);
    const [data, setData] = useState([]);
    const[location, setLocation] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://property-stock-backend.vercel.app/plot/approved');
                const data = response.data.response;
                setData(data);
            } catch (error) {
                console.error('Error fetching Data', error);
            }
        };
        fetchData();
    }, [])

    
    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }

    const [selectedCategory, setSelectedCategory] = useState('rental');
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const MIN = 0;
    const MAX = 2000000000;
    const [values,setValues] = useState([MIN,MAX]);

    const formatSliderValue = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value.toString();
        }
    };
    let trimLocation = location.trim();
    
        let filterSearch = data.filter(item => {
            return item.location.toLowerCase().includes(trimLocation.toLowerCase());
        });
        // .map(item => item.location);

        
        // if(location.trim().length === 0){
            // let filter2 = filterSearch.filter(item => item.category === selectedCategory);
            let filter2 = location.trim().length === 0? data.filter(item => item.category === selectedCategory) : filterSearch.filter(item => item.category === selectedCategory);
            let filter3 = filter2.filter(item => 
            item.price &&
            item.price >= values[0] &&
            item.price <= values[1]
            );
        // }

    
    const submit = (event) => {
        // console.log(selectedCategory + selectedType + selectedBhk + values[0] + "-" + values[1]);
        // console.log(filter5);
        event.preventDefault();
        updateFilteredData(filter3.sort((a, b) => Number(b.rating) - Number(a.rating)));
        navigate('/plot&land/results');

    }


    return(
        <>
        <div className="filter-body">
            
            <div className="filter-title">
                <h1>Plot & Land Search</h1>
            </div>

            <form onSubmit={submit}>
            <div className="filter-location">
                <input
                    type="text"
                    placeholder="Enter Location"
                    onChange={handleLocationChange}
                    value={location}
                />
            </div>
            <div className="filter-category">
                <div className="text">
                    <h2>Category :</h2>
                </div>
                <div className="radio-container">
                <label className="radio-label">
                    <input
                    type="radio"
                    value="rental"
                    checked={selectedCategory === 'rental'}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === 'rental' ? 'checked' : ''}`}>
                    Rental
                    </div>
                </label>
                <label className="radio-label">
                    <input
                    type="radio"
                    value="buy-sale"
                    checked={selectedCategory === "buy-sale"}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === "buy-sale" ? 'checked' : ''}`}>
                    Buy/Sale
                    </div>
                </label>
                </div>
            </div>

            <div className="filter-price">
                <div className="sliderContainer">
                    <Slider className={"slider"}
                            onChange={setValues}
                            value={values}
                            min={MIN}
                            max={MAX} 
                    />
                </div>

                <div className="label">
                    <h2>{formatSliderValue(values[0])}</h2>
                    <h2>{formatSliderValue(values[1])}</h2>
                </div>
                
            </div>

            <div className="filter-btn">
                <input className="submitBtn" type="submit"/>
            </div>
            </form>
        </div>
        <div className="extra"></div>
        
        </>
    );
}

export default PLFilter;