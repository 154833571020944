import "./landplotPage.css";
import SearchBar from "../../Components/SearchBar/SearchBar"
import DispCard from "../../Components/Display/DispCard";
import Advertisements from "../../Components/Advertisements/Advertisements";

const LandPlotPage = () => {

    let ad1 = "";

    const ads = [
        ad1,
    ].filter(plan => plan); 

    return(
        <>
        <div className="landplot">

            <div className="searchBarDiv">
                <SearchBar pageType="plot&land" />
            </div>

            <div className="titleDiv">
                <h1>Plots / Lands</h1>
            </div>

            <div className="ads">
                <Advertisements ads={ads} pageType="plotland"/>
            </div>

            <div className="outputDiv">
                <DispCard pageType="plotland"/>
            </div>

            <div className="extraSpace"></div>

        </div>
        <div className="extraSpace"></div>
        <div className="extraDiv"></div>
        </>
    );
}

export default LandPlotPage;