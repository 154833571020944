import "./DispCard.css";
import ResidentialCard from "../Cards/ResidentialCard";
import CommercialCard from "../Cards/CommercialCard";
import PlotLandCard from "../Cards/PlotLandCard";
import { useState, useEffect, useContext } from "react";
import { FilterDataContext } from '../../Context/FilterContext';
import axios from 'axios';

const DispCard = ({ pageType }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { updatePageType } = useContext(FilterDataContext);
    const [ratingData, setRatingData] = useState([]);

    const cardComponents = {
        residential: ResidentialCard,
        commercial: CommercialCard,
        plotland: PlotLandCard
    };
    

    useEffect(() => {
        let url = "";
        // Determine URL based on pageType
        if (pageType === "residential") {
            url = "https://property-stock-backend.vercel.app/residential/approved";
        } else if (pageType === "commercial") {
            url = "https://property-stock-backend.vercel.app/commercial/approved";
        } else if (pageType === "plotland") {
            url = "https://property-stock-backend.vercel.app/plot/approved";
        } else {
            setLoading(false); // Invalid pageType, stop loading
            return;
        }

        axios.get(url)
            .then((res) => {
                const sortedData = res.data.response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setData(sortedData);
                updatePageType(pageType);
                setRatingData(sortedData.sort((a, b) => Number(b.rating) - Number(a.rating)));

            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [pageType, updatePageType]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>; // Display the specific error message
    }

    if (!data || data.length === 0) {
        return <div>No Data Found</div>;
    }

    return (
        <div className="dispCards">
            {ratingData.map((e) => {
                const CardComponent = cardComponents[pageType];
                if (!CardComponent) {
                    return null; // Handle case where card component is not found
                }
                return (
                    <div key={e._id}>
                        <CardComponent {...e} pageType={pageType} />
                    </div>
                );
            })}
        </div>
    );
};

export default DispCard;
