import "./privacy.css";

const Privacy = () => {
    return(
        <div className="privacyContainer">
            <div className="privacyTop">
                <h1>Privacy Policy</h1>
                <h3>This site is unofficial & intended Solely for informational use.The website/Portal is not gating or not required end user personal Detail or Data.To ensure the protection of your personal data, we have developed a thorough Privacy that prioritizes your interests & data security on our platform.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Updation of Privacy policy-</h2>
                <h3>This Privacy policy is subject to undergo change & review without any prior notice or approvals. So to keep yourself updated on the changes introduced, please keep visiting & reviewing the terms & conditions of this privacy policy.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Children's Privacy-</h2>
                <h3>Our Services does not address anyone under the of 18. We don't knowingly collecting personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your Child has provided Us with personal Data, please Contact Us. If We become aware that we have collected personal Data/information from anyone under the age of 18 without verification of parental Consent, we take steps to remove that information from Our Side. We may update or Change Our privacy Policy without any update/notes time to time</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Interpretations & Definitions-</h2>
                <h3>
                    1. Data shall mean personal Information, including Sensitive personal Information &  special category personal data ( as defined under Data protection Laws) about you,  which we collect, receive, or otherwise process in connection with your use of our website/Portal.
                    <br/><br/>
                    2. Data Protection Laws Shall mean any applicable Law for the time being in force relating to the processing of data.
                    <br/><br/>
                    3. Services Providers including entities which provide Services to & to whom we may disclose your data for a specific purpose pursuant to a written contract
                    <br/><br/>
                    4. Propertiesstock/we shall Consulting & Services provides company
                    <br/><br/>
                    5. User or You shall normal end user easily using the our Website/Portal.
                </h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>User Detail and Utilization-</h2>
                <h3>Accessing our website/Portal implies acceptance of our terms, granting us permission to gather and utilize any voluntarily submitted Information. By doing So, you Consent to Communicate from propertiesstock through WhatsApp, SMS, phone, Email, etc.</h3>
            </div>
            <div className="privacyStandardMiddle">
                <h2>Security-</h2>
                <h3>
                    1. In terms of Security, we ensure the protection of Sensitive data/personal information by encrypting it during transmissions between the client and server. Additionally, strict measures are implementated to limit employee access to personal Information, maintaining a high level of confidentiality.
                    <br/><br/>
                    2. Cookies may be employed for security, session continuity, and customization reasons, although rejecting them could restrict access to specific services or features.
                </h3>
            </div>
            <div className="privacyStandardMiddle">
                <h3>For any inquiries or feedback regarding privacy concerns or interactions with this website/Portal, please feel free to reach out to us. It & aposs essential to note that this website/Portal the official platform; it's purpose is Solely informational.
                </h3>
                <h3>Content Us- </h3>
                <h3>propertiesstock08@gmail.com</h3>
            </div>
            <div className="privacyStandardMiddle"/>
        </div>
    );
}

export default Privacy;