import "./financialPage.css";
import Whatsapp from "../../Components/Whatsapp/Whastapp";
import HomeMenu from "../../Components/HomeMenu/HomeMenu";
import WAIcon from "../../Assets/whatsapp.svg";
import Advertisements from "../../Components/Advertisements/Advertisements";

const FinancialPage = () => {

        const redirectToWhatsApp = () => {
                const whatsappURL = "https://wa.me/+917718858689"; 
                window.location.href = whatsappURL;
        };

        let ad1 = "";
    let ad2 = "";

    const ads = [
        ad1,
        ad2,
    ].filter(plan => plan); 


    return(
        <>
        <div className="financial">
        
            <div className="homeMenu">
            {/* <HomeMenu/> */}
            </div>

            <div className="top">
                <div className="text">
                    <h1>Financial / Investment</h1>
                </div>
                <div className="whatsapp">
                <Whatsapp/>
                </div>
            </div>

            <div className="ads">
                <Advertisements pageType="financial"/>
            </div>

            <div className="services">
                <h2>&bull; Home Loan</h2>
                <h2>&bull; Commercial Loan</h2>
                <h2>&bull; Financial Services</h2>
                <h2>&bull; Insurance</h2>
                <h2>&bull; Transmission of Shares</h2>
                <h2>&bull; Unclaimed Dividend</h2>
                <h2>&bull; Dematerialization Of Shares</h2>
                <div className="extra">

                </div>
            </div>

            <div className="servicewithWA">
                <div className="WAService">
                        <h2>&bull; Home Loan</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Commercial Loan</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Financial Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Insurance</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Transmission of Shares</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Unclaimed Dividend</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Dematerialization Of Shares</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="extra">

                </div>
                <div className="extraSpace"></div>
                <div className="extraDiv"></div>
            </div>

        </div>
        </>
    );
}

export default FinancialPage;